html, body{
    height: 100%;
}

ul{
    padding: unset;
}

a:hover{
    text-decoration: none;
}

/* Microsoft fonts */
.ms-font-l{
    color: #0078d7;
    margin-bottom: 7px;
}

.ms-font-xl{
    font-weight: 400;
}

.ms-font-m{
    margin-top: 5px;
    width: 90%;
    padding: .71429em 1.14286em;
}

.ms-font-s{
    font-size: 14px;
}

/* Microsoft styling components  */

.ms-Icon, .ms-Icon--circle{
    vertical-align: bottom;
    display: inline;
    color: #a80000;
}

.ms-Button--primary{
    width: 100%;
    margin-bottom: 10%;
}

/* Angular Material styling components */

.mat-button-wrapper{
    color: #0078d7;
}

.mat-snack-bar-container{
    background-color: #fff;
    color: #333;
}

/* Bootstrap styling components */

#dropdownMenuButton{
    text-align: left;
    width: 100%;
    font-family: Segoe UI WestEuropean,Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
}

.dropdown-toggle::after {
    float: right;
    margin: 8px 15px;
}

.dropdown-menu{
    width: 100%;
    text-align: left;
    font-family:  Segoe UI WestEuropean,Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
}

.buttonsModalFooter{
    width: 48%;
    margin: 2.5px;
}

/* global styling elements */

.section{
    margin-bottom: 20px;
    display: block;
}

.errorBody{
    margin-left: 7%;
}

.errorText{
    margin-bottom: 8%;
}

.error{
    margin: 3%;
}

.errorStatus{
    margin: 3%;
    color: #a80000;
    padding: unset;
    display: inline;
}

.errorHeadline{
    margin-bottom: 5%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
